import { createContext, FC, PropsWithChildren, useEffect, useState } from 'react'
import { useAccount, useConnect, useSigner } from 'wagmi'

export interface UserContextProps {
  account: any
  isGnosis: boolean
}

export const UserContext = createContext<UserContextProps>({
  account: undefined,
  isGnosis: false
})

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { activeConnector } = useConnect()
  const { data: account } = useAccount()
  const { data: signer } = useSigner()
  const [customAccount, setCustomAccount] = useState<any>()
  const [isGnosis, setIsGnosis] = useState<boolean>(false)

  useEffect(() => {
    const fetchAccount = async () => {
      if (activeConnector) {
        if (signer && activeConnector.id === 'safe') {
          setIsGnosis(true)
          const address = await signer.getAddress()
          setCustomAccount({ address })
        } else if (activeConnector.id !== 'safe') {
          setCustomAccount(account)
          setIsGnosis(false)
        }
      }
    }

    fetchAccount()
  }, [activeConnector])

  return (
    <UserContext.Provider value={{ account: customAccount, isGnosis }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
